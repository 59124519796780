canvas {
    margin: auto;
}

#myProgress {
    position: absolute;
    top: 40px;
    width: calc(100% - 40px);
    left: 20px;
    height: 40px;
    border: 4px solid black;
    border-radius: 5px;
    box-sizing: border-box;
    overflow: hidden;
    z-index: -1;
}

#myBarBackground {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #ED8B00;
    z-index: -3;
    box-sizing: border-box;
}

#myBar {
    position: absolute;
    width: 0%;
    height: 100%;
    // height: 20px;
    background-color: green;
    // transition: width .5s linear;
    overflow: hidden;

    z-index: -2;
}
