canvas {
  margin: auto;
}

#myProgress {
  box-sizing: border-box;
  z-index: -1;
  border: 4px solid #000;
  border-radius: 5px;
  width: calc(100% - 40px);
  height: 40px;
  position: absolute;
  top: 40px;
  left: 20px;
  overflow: hidden;
}

#myBarBackground {
  z-index: -3;
  box-sizing: border-box;
  background-color: #ed8b00;
  width: 100%;
  height: 100%;
  position: absolute;
}

#myBar {
  z-index: -2;
  background-color: green;
  width: 0%;
  height: 100%;
  position: absolute;
  overflow: hidden;
}

/*# sourceMappingURL=index.8cc12f22.css.map */
